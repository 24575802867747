@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
html,
body,
#root {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

* {
  font-family: 'Roboto', sans-serif;
  color: #454545;
}

.chakra-alert * {
  color: unset;
}

.chakra-alert {
  padding: 30px !important;
}

.chakra-modal__content-container {
  width: 100% !important;
  height: 100% !important;
}

.chakra-modal__body {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.chakra-modal__content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 10px !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ReactModalPortal {
  z-index: 9999;
}

input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.35);
}

input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.35);
}

input::placeholder {
  color: rgba(0, 0, 0, 0.35);
}

@media screen and (min-width: 1281px) {
  html {
    zoom: 110%;
  }
}

@media screen and (min-width: 1681px) {
  html {
    zoom: 115%;
  }
}

